import React from "react"
// Components
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from '../components/layout/layout'
import Article from '../components/article/article'

const Category = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <Seo title={category} description={"Codespot " + category} />
      <h1>{'Category: ' + category}</h1>
      <div className='row'>
        {edges.map(( { node } ) => {
          return (
            <div key={node.id} className='col-lg-4'>
              <Article article={node}/>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default Category
export const categoryQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            path
            date
            title
            author
            image
            category
            tags
          }
        }
      }
    }
  }
`